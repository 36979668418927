import Vue from 'vue'

// base library
import { 
    Cell,
    CellGroup,
    Button,
    Switch,
    NumberKeyboard,
    Form, Field,
    Sticky,
    RadioGroup,
    Radio,
    Checkbox,
    CheckboxGroup,
    Picker,
    Popup,
    Calendar,
    Toast,
    Cascader,
    DatetimePicker 
   } from 'vant';

Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(NumberKeyboard);
Vue.use(Switch);
Vue.use(Button)
Vue.use(Sticky);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Calendar);
Vue.use(Toast);
Vue.use(Cascader);
Vue.use(DatetimePicker);